<template>
  <div />
</template>

<script>
export default {
  name: 'Dashboard',
  metaInfo () {
    return {
      title: `${this.$t('dashboard.title')} | `,
    };
  },
};
</script>
